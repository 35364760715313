// import { Loading } from 'element-ui';
import { checkUrl } from '@/utils/utils'
import {Base64} from 'js-base64';
import {clientId, clientSecret, tokenHeader} from "@/config"
import {Message} from 'element-ui';
import {getBladexDatavToken, clearBladexDatavToken} from '@/utils/auth'

import axios from 'axios';
window.$glob = {
  url: '',
  params: {},
  query: {},
  header: {}
};
function getGlobParams () {
  var query = window.location.search.substring(1);
  query = query.split("&");
  query.forEach(ele => {
    var pair = ele.split("=");
    window.$glob.params[pair[0]] = pair[1]
  })
}
getGlobParams();
axios.defaults.timeout = 10000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};


//跨域请求，允许保存cookie
// let loadingInstance = '';
axios.defaults.withCredentials = true;


//http request拦截
axios.interceptors.request.use(config => {
  // loadingInstance = Loading.service({
  //   text: '拼命加载中',
  //   background: 'rgba(0,0,0,0)',
  //   spinner: 'el-icon-loading'
  // });
  //headers判断是否需要
  const authorization = config.authorization === false;
  if (!authorization) {
    config.headers['Authorization'] = `Basic ${Base64.encode(`${clientId}:${clientSecret}`)}`;
  }
  //让每个请求携带token
  const meta = (config.meta || {});
  const isToken = meta.isToken === false;
  let token = getBladexDatavToken();
  if (token && token['access_token'] && !isToken) {
    config.headers[tokenHeader] = 'bearer ' + token['access_token']
  }
  //headers中配置text请求
  if (config.text === true) {
    config.headers["Content-Type"] = "text/plain";
  }

  if (!checkUrl(config.url)) config.url = window.$glob.url + config.url;
  let header = window.$glob.header || {};
  config.headers = Object.assign(config.headers, header);
  let data = window.$glob.query || {}
  let key;
  if (config.method == 'get') {
    key = "params"
  } else if (config.method == 'post') {
    key = "data"
  }
  if (typeof (config[key]) === 'object') {
    config[key] = Object.assign(config[key] || {}, data)
  }
  return config
}, error => {
  return Promise.reject(error)
});


//HTTPrequest拦截
axios.interceptors.response.use(res => {
  // loadingInstance.close();
  //获取状态码
  const status = res.data.code || res.status;
  //const statusWhiteList = website.statusWhiteList || [];
  const message = res.data.msg || res.data.error_description || '未知错误';
  //如果是401则跳转到登录页面
  if (status === 401) {
    clearBladexDatavToken();
    window.location.href = '/login'
    // router.push({path: '/login'})
  }
  // 如果请求为非200否者默认统一处理
  if (status !== 200) {
    Message({
      message: message,
      type: 'error'
    });
  }

  if (status === 200) {
    return res;
  }
}, error => {
  // loadingInstance.close();
  return Promise.reject(new Error(error));
})

export default axios;
