const storeAuthKey = 'bladex_datav_token';
/**
 * 获取 auth 信息
 * @returns {*}
 */
export function getBladexDatavToken() {
    let auth = localStorage.getItem(storeAuthKey);
    return JSON.parse(auth);
}

/**
 * 设置 auth 信息
 * @returns {any}
 */
export function setBladexDatavToken(auth) {
    localStorage.setItem(storeAuthKey, JSON.stringify(auth));
    return auth;
}

/**
 * 清理 auth 信息
 */
export function clearBladexDatavToken() {
    localStorage.removeItem(storeAuthKey);
}